<template>
    <v-data-table
        :headers="headers"
        :items="list"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка..."
        :server-items-length.sync="pagination.totalItems"
        :items-per-page="pagination.itemsPerPage"
        :options.sync="pagination"
        :footer-props="{
            'items-per-page-text':'Элементов на странице:',
            'items-per-page-all-text':'Все',
            'items-per-page-options':pagination.rowsPerPageItems
        }"
        @pagination="tablePagination"
    >
         <template v-slot:top>
            <v-dialog
                v-model="dialogEdit"
                max-width="500px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="emptyData"
                    >
                    Добавить мероприятие
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-tabs v-model="curTab" background-color="transparent" color="basil" grow>
                        <v-tab>Основное</v-tab>
                        <v-tab>Контент</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="curTab">
                        <v-tab-item>
                            <v-card color="basil" flat>
                                <v-card-text class="date_picker_field">
                                    <v-combobox
                                        v-model="editedItem.date"
                                        label="Дата мероприятия"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        @focus="date_picker1 = true;"
                                        @change="haveEditorChanges = true"
                                    ></v-combobox>
                                    <v-date-picker
                                        v-model="editedItem.date"
                                        v-show="date_picker1"
                                        elevation="15"
                                        locale="ru-ru"
                                        class="date_picker_popup"
                                        @input="date_picker1 = false"
                                        @blur="date_picker1 = false"
                                    ></v-date-picker>
                                </v-card-text>
                            </v-card>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-text-field
                                        label="Заголовок"
                                        v-model="editedItem.name"
                                        @change="haveEditorChanges = true"
                                    ></v-text-field>
                                </v-card-text>
                            </v-card>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-select
                                        label="Категории"
                                        v-model="editedItem.tags"
                                        :items="tags"
                                        :menu-props="{ maxHeight: '400' }"
                                        multiple
                                        persistent-hint
                                        item-text="name"
                                        item-value="id"
                                        @change="haveEditorChanges = true"
                                    ></v-select>
                                </v-card-text>
                            </v-card>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-file-input
                                        label="Картинка анонса"
                                        accept="image/jpeg, image/png"
                                        v-model="editedItem.newPreview"
                                        @change="haveEditorChanges = true"
                                    ></v-file-input>
                                    <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.id > 0">
                                        <v-img
                                            :src="$store.state.api + editedItem.preview + '?v=' + Date.now()"
                                            max-height="200"
                                            :contain="true"
                                        />
                                    </div>
                                </v-card-text>
                            </v-card>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <v-checkbox
                                        label="Скрыть мероприятие?"
                                        v-model="editedItem.hide"
                                        @change="haveEditorChanges = true"
                                    ></v-checkbox>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card color="basil" flat>
                                <v-card-text>
                                    <content-editor :list.sync="editedItem.content"></content-editor>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            :color="haveEditorChanges?'error darken-1':'blue darken-1'"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <file-uploader ref="uploader" />
            </v-dialog>

            <v-dialog
            v-model="dialogRemove"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Удаление мероприятия</span>
                    </v-card-title>

                    <v-card-text>Вы действительно хотите удалить выбранное мероприятие?</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeRemove"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="removeItem(itemToRemove)"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить мероприятие"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="openRemoveDialog(item)"
                title="Удалить мероприятие"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:item.hide="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
    </v-data-table>
</template>

<script>
import FileUploader from '@c/FileUploader.vue';
import ContentEditor from '@c/ContentEditor.vue';
export default {
    name: 'Events',
    components: {
        ContentEditor,
        FileUploader
    },
    data() {
        return {
            list: [],
            tags: [],
            user: 0,
            headers: [
                {
                    value: 'id',
                    text: 'ID',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: 'Заголовок',
                    sortable: false,
                },
                {
                    value: 'tags_string',
                    text: 'Категории',
                    sortable: false,
                },
                {
                    value: 'datePub',
                    text: 'Дата',
                    sortable: false,
                },
                {
                    value: 'views',
                    text: 'Просмотры',
                    sortable: false,
                },
                {
                    value: 'user_string',
                    text: 'Пользователь',
                    sortable: false,
                },
                {
                    value: 'hide',
                    text: 'Скрыть?',
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pagination: {
                page: 1,
                itemsPerPage: 10,
                totalItems: -1,
                rowsPerPageItems: [10, 20, 50, 100, -1]
            },
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                tags: [],
                preview: [],
                newPreview: [],
                userId: -1,
                hide: false,
                content: [],
                date: '',
            },
            dialogRemove: false,
            itemToRemove: -1,
            curTab: null,
            date_picker1: false,
            haveEditorChanges: false,
        };
    },
    watch: {
        dialogEdit (val) {
            this.curTab = null;
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.getList(this.pagination.page);
        this.getTags();
        this.getUser();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новое мероприятие' : 'Редактирование мероприятия #' + this.editedItem.id;
        },
    },
    methods: {
        tablePagination (data) {
            this.getList(data.page);
        },
        emptyData () {
            const dt = new Date();
            this.editedItem = {
                id: -1,
                name: '',
                tags: [],
                preview: [],
                newPreview: [],
                userId: -1,
                hide: false,
                content: [],
                date: dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate(),
            };
        },
        async getList(pg = 1) {
            this.loading = true;
            const on = this.pagination.itemsPerPage;
            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/events/?${new URLSearchParams({
                    pg,
                    on,
                })}`);

                list.forEach((singleNew, index) => {
                    let tag_names = [];

                    singleNew.tags.forEach((tag) => {
                        tag_names.push(tag.name);
                    });

                    list[index].tags_string = tag_names.join(', ');

                    list[index].user_string = '[' + singleNew.user[0].id + '] ' + singleNew.user[0].login;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pagination.page = pg;
                this.pagination.totalItems = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getTags() {
            try {
                const { list } = await this.$api('/admin/tags');

                this.tags = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getUser() {
            try {
                let userId = await this.$api('/current');
                this.user = userId.id;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api('/admin/events/' + item.id);
                this.curTab = null;
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api('/admin/events/' + item.id, {
                    method: 'DELETE'
                });
                if (!r.error) {
                    this.getList(this.pagination.page);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async uploadPreview() {
            if ((this.editedItem.newPreview !== null) &&
                (this.editedItem.newPreview !== undefined) &&
                (typeof this.editedItem.newPreview === 'object') &&
                (this.editedItem.newPreview.name !== undefined)) {
                // (this.editedItem.newPreview.length > 0) &&
                const needUpload = [];
                this.editedItem.newPreview.id = this.editedItem.id;
                needUpload.push(this.editedItem.newPreview);
                delete this.editedItem.newPreview;
                const files = await this.$refs.uploader.upload(needUpload);
                for (let f of files) {
                    if (f.id == this.editedItem.id) {
                        let fileData = {
                            tmp: f.tmp,
                            name: f.name,
                            size: Math.ceil(f.size / 1024 / 1024),
                            type: f.type.split('/')[1]
                        };
                        return fileData;
                    }
                }
            }
            return false;
        },
        async uploadContent() {
            if (this.editedItem.content) {
                let uploadedFiles = [];
                for (let block of this.editedItem.content) {
                    const key = this.editedItem.content.indexOf(block);
                    if (parseInt(block.type) === 5 || parseInt(block.type) === 6) {
                        const needUpload = [];
                        if (block.data.value.size != undefined) {
                            needUpload.push(block.data.value);
                            delete block.data.value;

                            const files = await this.$refs.uploader.upload(needUpload);
                            for (let f of files) {
                                let fileData = {
                                    tmp: f.tmp,
                                    name: f.name,
                                    size: Math.ceil(f.size / 1024 / 1024),
                                    type: f.type.split('/')[1]
                                };
                                uploadedFiles[key] = fileData;
                            }
                            if (files.length > 0) {
                                return uploadedFiles;
                            }
                        }
                    }
                    if ((parseInt(block.type) === 7) || (parseInt(block.type) === 8)) {
                        if (Array.isArray(block.data.value)) {
                            const needUpload = [];
                            let num = 0;
                            for (let fitem of block.data.value) {
                                if (fitem.size != undefined) {
                                    fitem.id = num;
                                    needUpload.push(fitem);
                                    num++;
                                }
                            }
                            delete block.data.value;
                            block.data.value = [];
                            const files = await this.$refs.uploader.upload(needUpload);
                            for (let f of files) {
                                if (uploadedFiles[key] == undefined) {
                                    uploadedFiles[key] = [];
                                }
                                uploadedFiles[key].push({
                                    image: f.tmp,
                                    name: f.name,
                                    size: Math.ceil(f.size / 1024 / 1024),
                                    type: f.type.split('/')[1]
                                });
                            }
                            if (files.length > 0) {
                                return uploadedFiles;
                            }
                        } else {
                            if (parseInt(block.type) === 8) {
                                if ((block.names !== undefined) && (Array.isArray(block.names))) {
                                    block.data.value.name = block.names.join(',');
                                }
                            }
                        }
                    }
                }
            }
            return false;
        },
        async save () {
            if (this.editedItem.tags.length == 0) {
                this.$error('Категория не выбрана. Мероприятие не может быть сохранено.');
                return;
            }

            if (this.editedItem.name.length == 0) {
                this.$error('Название не задано. Мероприятие не может быть сохранено.');
                return;
            }
            if (((this.editedItem.newPreview == null) || (isNaN(this.editedItem.newPreview.size))) && (this.editedItem.preview === null)) {
                this.$error('Изображение для анонса не задано. Мероприятие не может быть сохранено.');
                return;
            }
            const previewFile = await this.uploadPreview();
            const contentFiles = await this.uploadContent();
            if (contentFiles.length > 0) {
                contentFiles.forEach((block, index) => {
                    if (this.editedItem.content[index].type == 5) {
                        this.editedItem.content[index]['data'] = block;
                    }
                    if (this.editedItem.content[index].type == 6) {
                        this.editedItem.content[index]['data'] = block;
                    }
                    if (this.editedItem.content[index].type == 7) {
                        this.editedItem.content[index]['data']['list'] = block;
                    }
                    if (this.editedItem.content[index].type == 8) {
                        this.editedItem.content[index]['data']['list'] = block;
                    }
                });
            }

            let t_ids = []; 
            this.editedItem.tags.forEach((tag) => {
                if (tag.id) {
                    t_ids.push(tag.id);
                } else {
                    t_ids.push(tag);
                }
            });

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api('/admin/events/' + this.editedItem.id, {
                        method: 'PUT',
                        body: {
                            name: this.editedItem.name,
                            tag_ids: t_ids,
                            hide: this.editedItem.hide,
                            user_id: this.user,
                            poster: ((previewFile === false)||(previewFile === null))?'':previewFile.tmp,
                            date: this.editedItem.date,
                            content: this.editedItem.content
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api('/admin/events', {
                        method: 'POST',
                        body: {
                            name: this.editedItem.name,
                            tag_ids: t_ids,
                            hide: this.editedItem.hide,
                            user_id: this.user,
                            poster: ((previewFile === false)||(previewFile === null))?'':previewFile.tmp,
                            date: this.editedItem.date,
                            content: this.editedItem.content
                        },
                    });
                    if (!r.error) {
                        this.getList(this.pagination.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
            const dt = new Date();
            this.editedItem = {
                id: -1,
                name: '',
                tags: [],
                preview: [],
                newPreview: [],
                userId: -1,
                hide: false,
                content: [],
                date: dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate(),
            };
        }
    }
};
</script>

<style scoped>
    .date_picker_field {
        position: relative;
    }

    .date_picker_popup {
        z-index: 100;
        position: absolute;
    }
</style>
